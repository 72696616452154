import React from "react"
import "../layout/layout.css"
import * as contentModulStyles from "./listenModule.module.css"

export default function ListenModule(props, {
  headline,
}) {
  return (
    <div className="container-fluid">
      <div className={`row ${props.margin ? 'my-5' : 'my-0'} contentmodul ` +  props.backgroundclass}>
          <div className="col-md-4">
              <h2>{props.headline}</h2>
              {props.leftChildren}
          </div>
          <div className="col-md-8">

            {props.children}

          </div>
      </div>
    </div>
  );
}

ListenModule.defaultProps = {
  headline: "xyz",
  margin: true,
  backgroundclass: "greyback",
};
